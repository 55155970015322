import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function PreviousMonthToThisDay({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="previous month this day">
            <h3 className="number medium pcs"><Number>{data ? data.previousMonthThisDay.amountSold : '-'}</Number></h3>
            <div className="spaced-row">
                <h3 className="number sek small"><Number>{data ? data.previousMonthThisDay.totalSEK : '-'}</Number></h3>
            </div>
        </Card>
    )
}
