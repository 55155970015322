import React from 'react'

export default function Number({children, percentage, removeFormatting}) {

    

    let value = (children != undefined && !isNaN(children)) && children.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    if ((children != undefined && !isNaN(children))) {
        if (removeFormatting) value = children;
    
        if (percentage) value = (Math.round(children * 1000) / 10) + '%'

    }

    return (
        <>
        {value ? value : '-'}  
        </>
    )
}
