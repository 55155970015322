import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import ProductViewComponent from '../components/ProductView';
import Card from '../components/Card';
import DynamicText from '../components/DynamicText';
import TuesdayWine from '../util/TuesdayWine';
import { useAspectRatio } from '../util/Util';
import SoldToday from '../components/cards/SoldToday';
import PreviousMonthToThisDay from '../components/cards/PreviousMonthToThisDay';
import PreviousMonthThisDay from '../components/cards/PreviousMonthThisDay';
import SoldThisMonth from '../components/cards/SoldThisMonth';
import Annual from '../components/cards/Annual';
import Prognosis from '../components/cards/Prognosis';
import InStockFor from '../components/cards/InStockFor';
import PlanStockFor from '../components/cards/PlanStockFor';
import TotalStores from '../components/cards/TotalStores';
import PremiumStores from '../components/cards/PremiumStores';
import InStock from '../components/cards/InStock';
import SoldLastMonth from '../components/cards/SoldLastMonth';
import Stores from '../components/cards/Stores';
import StandardStores from '../components/cards/StandardStores';
import QuarterStock from '../components/cards/QuarterStock';
import SalesGraph from '../components/charts/SalesGraph';
import ForecastGraph from '../components/charts/ForecastGraph';
import Logo from '../components/Logo';
import ProductViewInfo from '../components/ProductViewInfo';
import Orders from '../components/cards/Orders';
import Loader from '../components/Loader';
import LoaderContext from '../context/LoaderContext';
import { useHistory } from 'react-router-dom';
import ProductView from '../components/ProductView';



export default function Product({match}) {


    let {productNumber, packageNumber} = match.params;

    const [data, setData] = useState();

    const {loader, setLoader} = useContext(LoaderContext);


    // Look for the product number.
    useEffect(() => {
        
        let run = async () => {
            setData(await TuesdayWine.getSummary(packageNumber ? `${productNumber}/${packageNumber}` : productNumber))
        }

        // Run every 30 minutes
        let interval = setInterval(run, 30 * 60 * 1000)

        return () => {
            clearInterval(interval);
        }
    }, [productNumber, packageNumber])

    useEffect(() => {
        setLoader({visible: true, message: `Förbereder produkt: ${productNumber}/${packageNumber}`});
        (async () => {
            setData(await TuesdayWine.getSummary(packageNumber ? `${productNumber}/${packageNumber}` : productNumber))
            setLoader((loader) => ({...loader, visible: false}));
        })()
    }, [productNumber, packageNumber])

    

    return (
        <Style>
            <ProductView data={data} productNumber={productNumber}></ProductView>
        </Style>
    )
}


const Style = styled.main`



`