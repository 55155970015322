import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function QuarterStock({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="in stock (1/4)">
            <h3 className="number medium pcs"><Number>{data && data.quarterStock.stockAmount}</Number></h3>
            <div className="row">
                <h4>last w sales:</h4>
                <div className="spacer wxs"></div>
                <h3 className="number pcs"><Number>{data && data.quarterStock.soldLastWeekAmount}</Number></h3>
            </div>
        </Card>
    )
}
