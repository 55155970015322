import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';

export default function Screens() {


    const {loader, setLoader} = useContext(LoaderContext);
    const [products, setProducts] = useState([]);

    const [val1, setVal1] = useState()
    const [val2, setVal2] = useState()


    useEffect(() => {
        setLoader({visible: true, message: `Laddar produkter...`});
        (async () => {
            setProducts(await TuesdayWine.getProducts())
            setLoader((loader) => ({...loader, visible: false}));
            setVal1((await TuesdayWine.get(`/screen/1`, true)).art_nr);
            setVal2((await TuesdayWine.get(`/screen/2`, true)).art_nr);
        })()
    }, [])

    return (
        <Style>
            <h2>Skärm 1:</h2>
            <select value={val1} onChange={async (e) => {
                setVal1(e.target.value);
                await TuesdayWine.request(`PUT`, `/screen/1`, {art_nr: e.target.value}, true);
            }}>
                <option value={'none'}>Välj...</option>
                {products.map(product => <option key={product.art_nr_full} value={product.art_nr_full}>{product.art_nr_full} - {product.name}</option>)}
            </select>

            <br/>
            <br/>
            <br/>

            <h2>Skärm 2:</h2>
            <select value={val2} onChange={async (e) => {
                setVal2(e.target.value);
                await TuesdayWine.request(`PUT`, `/screen/2`, {art_nr: e.target.value}, true);
            }}>
                <option value={'none'}>Välj...</option>
                {products.map(product => <option key={product.art_nr_full} value={product.art_nr_full}>{product.art_nr_full} - {product.name}</option>)}
            </select>
        </Style>
    )
}

const Style = styled.main`

`