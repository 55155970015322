import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function SoldLastMonth({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="sold last month">
            <h3 className="number medium pcs"><Number>{data ? data.soldLastMonth.amountSold : '-'}</Number></h3>
            <div className="spaced-row">
                <h3 className="number sek small"><Number>{data ? data.soldLastMonth.totalSEK : '-'}</Number></h3>
                {/* <h3 className="number positive small">+ 0%</h3> */}
            </div>
        </Card>
    )
}
