import React from 'react'
import styled from 'styled-components'

import loading_tw from '../assets/images/loading_tw.png';

export default function Loader({visible, children}) {
    return (
        <Style className={`loader ${visible ? 'visible' : 'hidden'}`}>
            <div className={`content`}>
                <img src={loading_tw} alt="loader"/>
                <h2>{children}</h2>
            </div>
        </Style>
    )
}

const Style = styled.section`

    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    background: var(--color-background);

    display: flex;
    align-items: center;
    justify-content: center;



    &.hidden {
        transition: opacity 500ms;
        opacity: 0;
        pointer-events: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 90%;

        @keyframes spin {
            0% {transform: rotate(0deg)}
            100% {transform: rotate(360deg)}
        }

        img {
            width: 10em;
            height: 10em;
            animation: spin 1s infinite linear;
        }

        h2 {
            margin-top: var(--margin-m);
            opacity: 0.5;
            text-align: center;
        }
    }

`
