import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function SoldThisMonth({data, mobileOrder}) {

    let percentualChange = data ? data.soldThisMonth.percentualChange : 0;

    return (
        <Card mobileOrder={mobileOrder} label="sold this month">
            <h3 className="number medium pcs"><Number>{parseInt(data && data.soldThisMonth.amountSold)}</Number></h3>
            <div className="spaced-row">
                <h3 className="number sek small"><Number>{data ? data.soldThisMonth.totalSEK : '-'}</Number></h3>
                <h3 className={`number ${percentualChange > 0 ? 'positive' : 'negative'} small`}><Number>{Math.round(percentualChange * 1000) / 10}</Number> %</h3>
            </div>
        </Card>
    )
}
