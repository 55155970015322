import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function InStock({data, mobileOrder}) {


    return (
        <Card mobileOrder={mobileOrder} label="in stock">
            <h3 className="number medium pcs"><Number>{Math.round(data && data.stock.stockAmount)}</Number></h3>
            <h3 className="number sek"><Number>{Math.round(data && data.stock.totalSEK)}</Number></h3>
        </Card>
    )
}
