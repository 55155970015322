import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function Stores({data, w, h, scale, mobileOrder}) {

    return (
        <Card mobileOrder={mobileOrder} >

            <div className="spaced-row">
                <h4>stores</h4>
                <h4>store stock</h4>
            </div>

            <div className="spaced-row">
                <h3 className="number"><Number>{data && data.actualStores.actualStores}</Number></h3>
                <h3 className="number"><Number>{data && data.actualStores.stockInStores}</Number></h3>
            </div>

            <div className="spacer auto"></div>

            <div className="spaced-row">
                <h4>premium</h4>
                <h4>standard</h4>
            </div>

            <div className="spaced-row">
                <h3 className="number"><Number>{data && data.actualStores.standardStores}</Number></h3>
                <h3 className="number"><Number>{data && data.actualStores.premiumStores}</Number></h3>
            </div>

        </Card>

    )
}
