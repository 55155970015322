import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function Annual({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="annual">
            <div>
                <h4>prognosis</h4>
                <h3 className="number small pcs"><Number>{Math.round(data && data.annual.prognosis)}</Number></h3>
            </div>
            <div>
                <h4>sales</h4>
                <h3 className="number small pcs"><Number>{Math.round(data && data.annual.sold)}</Number></h3>
            </div>
        </Card>
    )
}
