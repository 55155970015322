import React from 'react'
import styled from 'styled-components'
import { useDevices } from '../../util/Util';
import Number from '../Number';

export default function Orders({data}) {

    let orders = data ? data.incomingOrders : [];

    const [mobile, tablet, desktop] = useDevices();


    // Remove old orders.
    orders = orders.filter(order => {
        if (new Date(order.eta).getAbsoluteWeek() >= new Date().addDays(-7).getAbsoluteWeek()) return true;
        else return false;
    })

    //sort orders.
    orders = orders.sort((a,b) => {
        let diff = new Date(a.eta).getTime() - new Date(b.eta).getTime();

        if (diff > 0) return 1;
        if (diff < 0) return -1;
        return 0;
    });


    return (
        <Style className={`card`}>
            <table>
                <thead>
                    <tr>

                        {!mobile ? (
                            <>
                                <th><h4>incoming #</h4></th>
                                <th><h4>amount</h4></th>
                                <th><h4>estimated arrival</h4></th>
                                <th><h4>status</h4></th>
                            </>
                        ) : (
                            <>
                                <th>
                                    <h4>incoming #</h4>
                                    <h4>amount</h4>
                                </th>
                                <th>
                                    <h4>estimated arrival</h4>
                                    <h4>status</h4>
                                </th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => (
                        <tr key={index}>

                            {!mobile ? (
                                <>
                                    <LinkedTD order={order}>{order.ownOrderNr}</LinkedTD>
                                    <LinkedTD order={order} className="pcs"><Number>{order.amountPerUnit}</Number></LinkedTD>
                                    <LinkedTD order={order}>{`${order.eta} (v. ${new Date(order.eta).getWeek()})`}</LinkedTD>
                                    <LinkedTD order={order}>{order.shippingMode}</LinkedTD>
                                </>
                            ) : (
                                <>
                                    <LinkedTD order={order}>
                                        <div>{order.ownOrderNr}</div>
                                        <div className="pcs"><Number>{order.amountPerUnit}</Number></div>
                                    </LinkedTD>
                                    <LinkedTD order={order}>
                                        <div>{`${order.eta} (v. ${new Date(order.eta).getWeek()})`}</div>
                                        <div>{order.shippingMode}</div>
                                    </LinkedTD>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Style>
    )
}

const LinkedTD = ({order, className, children}) => (
    <td className={className}>
        <a href={`https://kylix.jfhillebrandlogistics.com/kylix/purchase_pkg.purchase_order_detail?p_order_no=${order.orderNr}`} target="_blank">
            {children}
        </a>
    </td>
)

const Style = styled.div`

    flex-shrink: 1;
    height: 10em;

    padding: 0;
    overflow-y: auto; 

    thead th { 
        position: sticky; 
        top: 0; 

        h4 {
            font-size: 1em;
        }
    }


        /* Just common table stuff. Really. */
    table { 
        border-collapse: collapse; 
        width: 100%; 

        th, td { 
            padding: 0.15em 1.0em; 
            background-color: #323232;
        
            a {
                color: white;
                text-decoration: none;
            }
        }

        th { 
            padding: 0.75em 1.0em; 
            text-align: left;
        }
    }

    
    .mobile & {
        height: auto;
        tr {
            font-size: 0.8em;
        }

        th, td { 
            padding: 0.5em 0.5em; 
        }
    }
    

`