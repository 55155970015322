import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import ProductView from '../components/ProductView';
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';

export default function Screen({match}) {


    let {id} = match.params;

    const [productNumber, setProductNumber] = useState()
    const [data, setData] = useState()
    
    const {loader, setLoader} = useContext(LoaderContext);


    // Look for the product number.
    useEffect(() => {
        setLoader({visible: true, message: `Laddar skärm: ${id}`});
        
        let run = async () => {
            let res = await TuesdayWine.get(`/screen/${id}`, true);
            setProductNumber(res.art_nr);
        }

        let load = async () => {
            if (productNumber == undefined) return;
            setData(await TuesdayWine.getSummary(productNumber))
        }

        run();
        let interval = setInterval(run, 5 * 1000)
        let dataInterval = setInterval(load, 30 * 60 * 1000)

        return () => {
            clearInterval(interval);
            clearInterval(dataInterval);
        }
    }, [id])


    // Load the product data.
    useEffect(() => {
        if (productNumber == undefined) return;
        
        setLoader({visible: true, message: `Förbereder produkt: ${productNumber}`});


        (async () => {
            setData(await TuesdayWine.getSummary(productNumber))
            setLoader((loader) => ({...loader, visible: false}));
        })()
    
    }, [productNumber])

    
    return (
        <Style>

            <ProductView data={data} productNumber={productNumber ? productNumber.split('/')[0] : ''}></ProductView>
        </Style>
    )
}


const Style = styled.main`



`