import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components'
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';
import defaultThumbnail from '../assets/images/default_wine.png';
import Logo from '../components/Logo';

export default function ProductList() {

    const history = useHistory();

    const {loader, setLoader} = useContext(LoaderContext);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        setLoader({visible: true, message: `Laddar produkter...`});
        (async () => {
            setProducts(await TuesdayWine.getProducts())
            setLoader((loader) => ({...loader, visible: false}));
        })()
    }, [])


    return (
        <Style>
            {/* <pre>
                {JSON.stringify(products[0], undefined, 2)}
            </pre> */}

            <Logo></Logo>

            <table>

                <thead>
                    <tr>
                        <th></th>
                        <th><h4>Product</h4></th>
                        <th><h4>Product code</h4></th>
                    </tr>
                </thead>

                <tbody>
                    {products.map(product => (
                        <tr key={product.id} onClick={() => {
                            history.push(`/${product.art_nr_full}`)
                        }}>
                            <td className={`image`}>{product.thumbnail ? <img src={product.thumbnail} alt={product.name}/> : <img src={defaultThumbnail} alt={product.name}/>}</td>
                            <td className={`name`}>{product.name}</td>
                            <td className={`art-nr`}>{product.art_nr_full}</td>
                        </tr>
                    ))}
                </tbody>

            </table>

        </Style>
    )
}


const Style = styled.main`

    padding: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        width: 8em !important;
        margin-bottom: 2em;
    }

    table {

        td, th {
            text-align: left;
        }

        tr {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            .image {
                img {
                    width: 4em;
                    height: 4em;
                    object-fit: contain;
                }
            }

            .name {
                padding-right: 3em;
            }
        }

    }


`