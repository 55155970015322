import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function SoldToday({data, w, h, mobileOrder}) {


    let percentualChange = data ? data.soldToday.percentualChange : 0;

    return (
        <Card mobileOrder={mobileOrder} w={w} h={h} label="sold today">
            <h1 className="pcs"><Number>{parseInt(data && data.soldToday.amountSold)}</Number></h1>
            <div className="spaced-row">
                <h3 className="sek"><Number>{data && data.soldToday.totalSEK}</Number></h3>
                <h3 className={percentualChange > 0 ? 'positive' : 'negative'}><Number>{Math.round(percentualChange * 1000) / 10}</Number> %</h3>
            </div>
        </Card>
    )
}
