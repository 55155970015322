import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function PreviousMonthToThisDay({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="previous month to this day">
            <h3 className="number medium pcs"><Number>{data && data.previousMonthToThisDay.amountSold}</Number></h3>
            <h3 className="number small sek"><Number>{data && data.previousMonthToThisDay.totalSEK}</Number></h3>
        </Card>
    )
}
