import React from 'react'
import { ComposedChart, Line, Area, Bar, XAxis, YAxis, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import styled from 'styled-components';
import { useDevices } from '../../util/Util';


const renderColorfulLegendText = (value, entry) => {

	const { color } = entry;

  	return <span style={{ color, fontSize: '0.8em' }}>{value}</span>;
}

const ForecastGraph = ({data}) => {

	let graphData = (data && data.forecastGraph) ? data.forecastGraph : [];


	const [mobile, tablet, desktop] = useDevices();

	graphData = graphData.map(d => {

		return {
			...d,
			predictedStock: d.predictedStock < 0 ? 0 : d.predictedStock,
		}
	})

	
	if (graphData && mobile) {
		graphData = graphData.filter((d,i) => i < 20);
	}



	return (
		<Style>
			<ResponsiveContainer width="100%" height={desktop ? 300 : 250}>
				<ComposedChart
					data={graphData}
					margin={{ top: 50, right: 0, bottom: 0, left: 0 }}
				>
					<defs>
						<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
							<stop offset="5%" stopColor="#8884d8" stopOpacity={0.4}/>
							<stop offset="75%" stopColor="#8884d8" stopOpacity={0}/>
						</linearGradient>
					</defs>
					<XAxis interval={1} dataKey='week'/>
					<YAxis hide={true} />
					
					{desktop && <Tooltip
						formatter={function(value, name) {
							return `${Math.round(value)}`;
						}}
						labelFormatter={function(value) {
							return `Vecka ${value}`;
						}}
						contentStyle={{
							border: '1px solid rgba(96, 96, 96, 0.47)'
						}}
					/>}

					<Legend formatter={renderColorfulLegendText} />
					<Area type='monotone' name="Predicted" dataKey='predictedStock' strokeWidth="0.1em"  stroke='#5E6999' fillOpacity={1} fill="url(#colorUv)" />
					<Bar dataKey='weekForecast' name="Forecast" barSize={4} fill='lightyellow' />
					<Bar dataKey='weekIncommingTotal' name="Incoming" barSize={4} fill='red' />
					<Line
						type='monotone'
						name="Safety Stock"
						dataKey='safetyStock'
						stroke='orange'
						dot={false}
						strokeDasharray='3 3'
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</Style>
	)
}

export default ForecastGraph


const Style = styled.div`


	.recharts-legend-item {
		svg {
			display: none !important;
		}
	}

	.recharts-text.recharts-cartesian-axis-tick-value {
		fill: white !important;
	}

	.recharts-area {
		path.recharts-curve recharts-area-curve {
			stroke: #5E6999 !important;
		}
	}
`