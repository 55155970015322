import React from 'react'
import styled from 'styled-components'
import Logo from './Logo'
import defaultWine from '../assets/images/default_wine.png'
import { useHistory } from 'react-router-dom'

export default function ProductViewInfo({data}) {
    const history = useHistory()

    return (
        <Style className="info">

            {/* <Logo onClick={() => history.push('/')}></Logo> */}

            <div className="product-info">
                <img src={(data && data.productInfo?.thumbnail) ? data.productInfo?.thumbnail : defaultWine} alt="placeholder"/>
                <div className="text">
                    <h2>{data && data.productInfo?.name}</h2>
                    <div className="spacer xs"></div>
                    <h4 className="thin">{data && data.productInfo?.art_nr_full}</h4>
                </div>
            </div>

        </Style>
    )
}

const Style = styled.div`

    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        width: 6em;
        margin-top: 1em;
    }

    .product-info {
        font-size: 1.5em;
        display: flex;
        align-items: center;
        width: 100%;

        img {
            width: 5em;
            height: 5em;
            object-fit: contain;
        }

        .text {
            margin-left: 1em;
            flex-grow: 1;

            h2 {
                font-size: 1.2em;
                max-width: 70%;
            }
        }
    }

    body.tablet &, body.mobile & {

        .logo {
            width: 4em;
            opacity: 0.4;
        }

        .product-info {
            margin-top: 1em;
            font-size: 1em;
            width: 100%;
            
            .text {
                margin-left: 0.5em;

                h2 {
                    font-size: 1.2em;
                }

                h4 {
                    font-size: 0.9em;
                }
            }
        }
    }

`