import React from 'react'
import styled from 'styled-components'
import { useDevices } from '../util/Util';

export default function Card({mobileOrder = undefined, children, scale = 1, w = undefined, h = undefined, transparent = false, label, justify = 'flex-start'}) {

    let [mobile, tablet, desktop] = useDevices()

    let style = {
        [`--scale`]: scale,
        [`--w`]: w,
        [`--h`]: h,
    }

    
    if (mobileOrder != undefined && mobile) style.gridRow = mobileOrder;

    return (
        <Style className={`card ${transparent ? `transparent` : ''} justify-${justify}`} style={style}>
            {label && (
                <>
                    <h4>{label}</h4>
                    <div className="spacer auto"></div>
                </>
            )}
            {children}
        </Style>
    )
}

const Style = styled.div`

    &.justify-space-between {
        justify-content: space-between;
    }

`

