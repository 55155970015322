import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function PlanStockFor({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="plan stock for">
            <div className="row">
                <h3 className="number big"><Number>{data ? data.weeksPlanning : '-'}</Number></h3>
                <div className="spacer wxs"></div>
                <h3 className="number small">WEEKS</h3>
            </div>
        </Card>
    )
}
