import Axios from "axios";
import md5 from "md5";

// const url = 'http://localhost:5000';
// const url = 'http://192.168.1.237:5000';
const url = 'http://server02.blackpixel.se:1403';
const CACHE_TTL = 60 * 5;
const CACHE_KEY = 'cache_';

export default class TuesdayWine {

    static getURL() {
        return url;
    }

    static clearCache(ep = undefined) {
        let keys = Object.keys(localStorage).filter(ls => 
            ep == undefined ?
            ls.startsWith(CACHE_KEY) :
            ls.startsWith(CACHE_KEY) && ls.includes(ep)
        );
        keys.map(key => localStorage.removeItem(key));
    }

    static async request(method, ep, body, noCache = false) {

        noCache = true;

        let cacheKey = CACHE_KEY + method + '_' + ep + '_' + md5(ep + JSON.stringify(body))
        let result = localStorage.getItem(cacheKey);
        
        if (result && !noCache) {
            result = JSON.parse(result);
            if (result && result.time) {
                if (new Date().getTime() - result.time <= CACHE_TTL * 1000) {
                    return result.result;
                }
            }
        }

        result = await (await fetch(`${url}${ep}`, {
            method: method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('user')
            },
        })).json();

        if (result.success == false) return undefined;

        if (!noCache) {
            localStorage.setItem(cacheKey, JSON.stringify({
                result,
                time: new Date().getTime()
            }))
        }

        return result
    }

    static async get(ep, noCache = false) {
        return await TuesdayWine.request('GET', ep, undefined, noCache);
    }

    static async getSummary(productNumber) {
        return await TuesdayWine.get(`/api/salesboard/${productNumber}`);
    }

    static async getProducts() {
        return await TuesdayWine.get(`/products`);
    }

}