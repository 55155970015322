import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function PremiumStores({data, mobileOrder}) {
    return (
        <Card mobileOrder={mobileOrder} label="premium stores">
            <div className="spaced-row">
                <h3 className="number positive"><Number percentage={true}>{data && data.stores.prognosed_mc_premium_percent}</Number></h3>
                <h3 className="number positive"><Number>{data && data.stores.prognosed_mc_premium_stores}</Number></h3>
            </div>
            <div className="spaced-row">
                <h3 className="number"><Number percentage={true}>{data && data.stores.upcoming_mc_premium_percent}</Number></h3>
                <h3 className="number"><Number>{data && data.stores.upcoming_mc_premium_stores}</Number></h3>
            </div>
            <div className="spaced-row">
                <h3 className="number"><Number percentage={true}>{data && data.stores.current_mc_premium_percent}</Number></h3>
                <h3 className="number"><Number>{data && data.stores.current_mc_premium_stores}</Number></h3>
            </div>

        </Card>
    )
}
