import React from 'react'
import Card from '../Card'
import Number from '../Number'

export default function Prognosis({data, w, h, scale, mobileOrder}) {

    /**
     * TODO
     * 
     * Vänta på annan data från API, ändra positive eller negative
     */


    let fourPercentage = data && data.forecast.fourWeeksAveragePercent - 1;
    let eightPercentage = data && data.forecast.eightWeeksAveragePercent - 1;

    return (
        <Card mobileOrder={mobileOrder} w={w} h={h} scale={scale} label="prognosis">
            <div className="spaced-row">
                <h3 className="number wp">4</h3>
                <h3 className="number pcs"><Number>{data && data.forecast.fourWeeksForecast}</Number></h3>
            </div>
            <div className="spaced-row">
                <h3 className={`number ${fourPercentage > 0 ? 'positive' : 'negative'} small`}><Number percentage={true} removeFormatting={true}>{fourPercentage}</Number></h3>
                <h3 className={`number ${fourPercentage > 0 ? 'positive' : 'negative'} small pcs`}>S <Number>{data && data.forecast.fourWeeksSales}</Number></h3>
            </div>


            <div className="spaced-row">
                <h3 className="number wp">8</h3>
                <h3 className="number pcs"><Number>{data && data.forecast.eightWeeksForecast}</Number></h3>
            </div>
            <div className="spaced-row">
                <h3 className={`number ${eightPercentage > 0 ? 'positive' : 'negative'} small`}><Number percentage={true} removeFormatting={true}>{eightPercentage}</Number></h3>
                <h3 className={`number ${eightPercentage > 0 ? 'positive' : 'negative'} small pcs`}>S <Number>{data && data.forecast.eightWeeksSales}</Number></h3>
            </div>


        </Card>
    )
}
