import React from 'react';
import 'bp-date-util';
import { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
	Redirect
} from "react-router-dom";
import styled from 'styled-components';
import Product from './pages/Product';
import CompJS from './util/Util';
import ProductList from './pages/ProductList';
import Loader from './components/Loader';
import { LoaderProvider } from './context/LoaderContext';
import { useState } from 'react';
import Screen from './pages/Screen';
import Screens from './pages/Screens';

Date.prototype.getAbsoluteWeek = function() {
	let w = this.getWeek() + '';
	if (w.length == 1) w = '0' + w;
	return Number(this.getFullYear() + w);
}


function App() {

	const [loader, setLoader] = useState({visible: true, message: 'loading...'})

	useEffect(() => {
		CompJS.init();
	}, [])

	return (
		<LoaderProvider value={{loader, setLoader}}>
			<Router>
				
				<Loader visible={loader.visible}>{loader.message}</Loader>
				
				<Switch>

					<Route path="/screens" component={Screens}></Route>
					<Route path="/screen/:id" component={Screen}></Route>
					<Route path="/:productNumber/:packageNumber" component={Product}></Route>

					<Route path="/" component={ProductList}></Route>


				</Switch>
			</Router>
		</LoaderProvider>
	);
}



const Style = styled.div`
	main {
		position: absolute;
		width: 100vw;
		transform: scale(0.5);
		transform-origin: 0 0;

		&:nth-child(2) {
			left: 50vw;
		}

		&:nth-child(3) {
			top: 50vh;
		}

		&:nth-child(4) {
			top: 50vh;
			left: 50vw;
		}

	}
`

export default App;
